<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            head-row-variant="primary"
            :fields="fields"
            :items="tableData"
            @row-clicked="customClicked"
            @row-dblclicked="edit"
            @row-contextmenu="handleContextMenuEvent"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(is_active)="data">
              <div v-if="data.item.is_active === 1">
                Активен
              </div>
              <div v-else>
                Не активен
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ new Date(data.item.created_at).toLocaleDateString() }}
            </template>
            <template #cell(updated_at)="data">
              {{ new Date(data.item.updated_at).toLocaleDateString() }}
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
                <b-link
                class="d-flex align-items-center"
                @click="removeCategoryTariff(id)"
                >
                <feather-icon
                    icon="DeleteIcon"
                    size="16"
                />
                <span class="ml-75">Удалить</span>
                </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
      VueContext
  },
  props: ['tableData'],
  data() {
    return {
      fields: [ 
        { key: 'checkbox', label: '', thStyle: {width: '30px'}},
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        { key: "name", label: "Название", sortable: true, thStyle: { width: "100px" } },
        { key: "is_active", label: "Статус", sortable: true, thStyle: { width: "100px" } },
        { key: "created_at", label: "Дата создания", sortable: true, thStyle: { width: "100px" } },
        { key: "updated_at", label: "Дата обновления", sortable: true, thStyle: { width: "100px" } },
      ],
      id: null,
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    customClicked() {
      cellSelect()
    },
    handleContextMenuEvent(item, index, event){
        event.preventDefault();
        this.$refs.menu.open(event)
        this.id = item.id
    },
    removeCategoryTariff(id) {
      this.$http
      .patch(`/category-tariff/delete/${id}`)
      .then(res => {
        this.$emit('refresh')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успешно удалено!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${res.data.message}`,
          },
        })
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Некорректные данные!',
            icon: 'XIcon',
            variant: 'danger',
            text: `${res.data.message}`,
          },
        })
      })
    },
    edit(item){
      this.$emit('openEditModal', item.id)
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card {
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
}
</style>
