<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-tariff-category @openEditModal="openEditModal" :tableData="tableData" @refresh="refresh"></table-tariff-category>
      <modal-tariff-category @refresh="refresh"></modal-tariff-category>
      <modal-tariff-category-edit @refresh="refresh" :id="id"></modal-tariff-category-edit>
    </div>
  </div>
</template>
<script>
import tableTariffCategory from '@/views/component/Table/tableTariffCategory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import modalTariffCategory from "@/views/component/Modal/ModalTariffCategory/modalTariffCategory.vue"
import modalTariffCategoryEdit from "@/views/component/Modal/ModalTariffCategory/modalTariffCategoryEdit.vue"
export default {
  components: {
    tableTariffCategory,
    ToastificationContent,
    modalTariffCategory,
    modalTariffCategoryEdit
  },
  data() {
    return {
      showPreloader: false,
      tableData: [],
      id: undefined
    }
  },
  methods: {
    refresh() {
      this.showPreloader = true
      this.$http
      .get('category-tariff')
      .then(res => {
        this.tableData = res.data
        this.showPreloader = false
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
          },
        })
      })
    },
    openEditModal(id){
      this.id = id
      this.$bvModal.show('editTariffCategory')
    }
  },
  mounted() {
    this.refresh()
  },
  computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.tableData = res.data
                    this.$store.commit('pageData/setdataCount', this.tableData.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>