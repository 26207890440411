<template>
  <b-modal
    id="editTariffCategory"
    title="Изменить"
    size="sm"
    @shown="getTariffCategory"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
  >
  <b-row>
    <b-col>
      <label for="">Название</label>
      <b-input v-model="tariffCategory.name"></b-input>
    </b-col>
  </b-row>
  <b-col cols="12" class="mt-1">
    <b-form-checkbox v-model="tariffCategory.is_active" checked="true" name="check-button" switch inline>
        {{this.tariffCategory.is_active ? 'Активный' : ' Не активный'}}
      </b-form-checkbox>
  </b-col>
  <b-row class="mb-1 mt-2">
    <b-col class="d-flex justify-content-end">
      <b-button :disabled="submitButtonDisabled || !tariffCategory.name" @click="update()" variant="primary">Изменить</b-button>
      <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
    </b-col>
  </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    ToastificationContent
  },
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      tariffCategory: {
        name: null,
        is_active: null
      },
      submitButtonDisabled: false
    }
  },
  methods: {
    closeModal() {
      this.tariffCategory.name = null
      this.submitButtonDisabled = false
      this.$bvModal.hide('editTariffCategory')
    }, 
    getTariffCategory() {
      this.$http
      .get(`category-tariff/${this.id}`)
      .then(res => {
        this.tariffCategory = res.data
        this.tariffCategory.is_active = res.data.is_active === 1 ? true : false
      })
    },
    update() {
      this.submitButtonDisabled = true
      this.tariffCategory.is_active = this.tariffCategory.is_active ? 1 : 0
      this.$http
      .patch(`category-tariff/update/${this.id}`, this.tariffCategory)
      .then(res => {
        this.closeModal()
        this.$emit('refresh')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успешно!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${res.data.message}`,
          },
        })
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Некорректные данные!',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Ошибка',
          },
        })
      })
      .finally(() => {
        this.submitButtonDisabled = false
      })
    }
  }
}
</script>