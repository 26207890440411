<template>
  <b-modal
    :id="$route.name + 'CreateModal'"
    title="Добавить"
    size="sm"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
  >
  <b-row>
    <b-col>
      <label for="">Название</label>
      <b-input v-model="tariffCategory.name"></b-input>
    </b-col>
  </b-row>
  <b-row class="mb-1 mt-2">
    <b-col class="d-flex justify-content-end">
      <b-button :disabled="submitButtonDisabled || !tariffCategory.name" @click="create()" variant="primary">Добавить</b-button>
      <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
    </b-col>
  </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    ToastificationContent
  },
  data() {
    return {
      tariffCategory: {
        name: null
      },
      submitButtonDisabled: false
    }
  },
  methods: {
    closeModal() {
      this.tariffCategory.name = null
      this.submitButtonDisabled = false
      this.$bvModal.hide(this.$route.name + 'CreateModal')
    },
    create() {
      this.submitButtonDisabled = true
      this.$http
      .post('category-tariff/create', this.tariffCategory)
      .then(res => {
        this.closeModal()
        this.$emit('refresh')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успех!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Успешно добавлено`,
          },
        })
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Некорректные данные!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
          },
        })
      })
      .finally(() => {
        this.submitButtonDisabled = false
      })
    }
  }
}
</script>